import { Button } from '@progress/kendo-react-buttons';
import { Loader } from '@progress/kendo-react-indicators';
import { isEmpty } from 'lodash';
import cloneDeep from 'lodash.clonedeep';
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  JSX
} from 'react';
import { Container } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useInterval } from 'usehooks-ts';
import { Gallery } from '../../components/gallery';
import { Answer } from '../../components/questionnaire';
import {
  AchievementContent,
  AchievementContext
} from '../../contexts/achievement';
import { GameDocumentContext } from '../../contexts/game-document';
import {
  NotificationContent,
  NotificationContext
} from '../../contexts/notification';
import { PlayerContext, PlayerContextState } from '../../contexts/player';
import { addManualScoringAsync } from '../../services/players';
import {
  AlgorithmControlStructure,
  AlgorithmStep
} from '../../types/algorithm';
import {
  TaskContentAnswerEntity,
  TaskEntity,
  TaskEntity as TaskEntityPlayer
} from '../../types/entities';
import {
  ContentAnswer,
  ScoringReductionEntity
} from '../../types/entities/task';
import { TaskContentEntity } from '../../types/game-document/entities';
import {
  AnswerType,
  CheckboxOption,
  TaskContentAnswer,
  TaskContentForm
} from '../../types/game-document/entities/task-content';
import { GetTaskById as GetTaskGameById } from '../../utils/game-document/assets';
import {
  GetCustomFeedback,
  GetFormMedia
} from '../../utils/game-document/assets/task-contents';
import { GetResourceValue } from '../../utils/game-document/resources';
import {
  AddTask,
  GetTaskById as GetPlayerTaskById,
  CalculateTotal,
  UpdateTaskAsync
} from '../../utils/game-engine/assets/tasks';
import { UpdatePlayerStateAsync } from '../../utils/game-engine/base';
import {
  ClearOpenedTask,
  GiveScore,
  setPlayerScoreReduction
} from '../../utils/game-engine/player';
import { giveScore as teamGiveScore } from '../../services/teams';
import {
  UploadSingleBlobAsync,
  UploadSingleFileAsync
} from '../../utils/storage';
import ProgressTimeRemaining from '../progress-time-remaining';
import { processMediaTags } from '../../utils/game-engine/media-tag-converter';
import { isContentHtmlEmpty } from '../../utils/game-engine/html-content';
import { generateTitleById } from '../../utils/game-document/display-languages';
import { DisplayLanguageContext } from '../../contexts/display-languages';
import { useGameTaskPosition } from '../../hooks/use-game-task-position';
import { getStepTaskCompletedMap } from '../../utils/game-engine/game';
import { TeamContext } from '../../contexts/team';

type TaskContentProps = {
  id: string;
  mapType: string;
  changeTaskIconToComplete: (taskId: string) => void;
  changeTaskIconToInComplete: (taskId: string) => void;
  className?: string;
  answerType?: AnswerType;
  taskDistance?: number;
  onAlgorithmExecuted?: (
    step: AlgorithmStep | AlgorithmControlStructure
  ) => void;
  isAccesible?: boolean;
};

const defaultImage = {
  defaultFeedbackAllCorrectIconResId: '/icons/checked.png',
  defaultFeedbackAllWrongIconResId: '/icons/cross.png'
};

const defaultFeedbackMessage = {
  defaultAllCorrectFeedbackMessageResId: 'Congratulations, you got it!',
  defaultAllWrongFeedbackMessageResId: "That's not the answer!",
  defaultPartialFeedbackMessageResId:
    'That answer was partially correct! Partial points awarded.'
};

export const TaskContent = ({ ...props }: TaskContentProps) => {
  const [gameDocument] = useContext(GameDocumentContext);
  const [player, setPlayer] = useContext(PlayerContext);
  const [displayLanguageContext] = useContext(DisplayLanguageContext);
  const [question, setQuestion] = useState<string>('');
  const [options, setOptions] = useState<string[]>([]);
  const [answerType, setAnswerType] = useState<AnswerType>();
  const [taskQuestion, setTaskQuestion] = useState<string>();
  const [preMessageContent, setPreMessageContent] = useState<string>();
  const [data, setData] = useState<TaskContentEntity>({} as TaskContentEntity);
  const [taskVisibility, setTaskVisibility] = useState<boolean>(true);
  const [formIndex, setFormIndex] = useState<number>(-1);
  const [contentAnswer, setContentAnswer] = useState<ContentAnswer[]>([]);
  const [checkboxOption, setCheckboxOption] = useState<CheckboxOption[]>([]);
  const [notification, setNotification] = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingPreMessageContent, setIsLoadingPreMessageContent] =
    useState<boolean>(false);
  const [isPreMessage, setIsPreMessage] = useState<boolean>(false);
  const [isTaskIntro, setIsTaskIntro] = useState<boolean>(false);

  const [totalTryAnswer, setTotalTryAnswer] = useState<number>(0);
  const [title, setTitle] = useState<string>('');
  const [toast, setToast] = useContext(AchievementContext);
  const [questionScoringReduction, setQuestionScoringReduction] =
    useState<ScoringReductionEntity>();
  const [counterTimeInterval, setCounterTimeInterval] = useState<number>(1);
  const [isScoringReduction, setIsScoringReduction] = useState<boolean>(false);
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const [showMediaSubmitConfirmation, setShowMediaSubmitConfirmation] =
    useState<boolean>(false);
  const [team, setTeam] = useContext(TeamContext);
  const { removeTaskOverlay, removeTaskDistance } = useGameTaskPosition({
    mapType: props.mapType
  });

  const hasMounted = useRef(false);

  const getTaskContent = async (id: string) => {
    const gameDocTask = GetTaskGameById(gameDocument.gameDocument, id);

    //Get taskContent from gameDocument
    const taskContent = gameDocument?.gameDocument?.assets?.taskContents?.find(
      (x) => x.id === gameDocTask!.taskContentId
    );

    setTitle(getTitle(gameDocTask!, taskContent));

    let isExistIntroContent = false;
    let isExistPreMessageContent = false;

    if (taskContent !== undefined) {
      setData(taskContent);
      checkTaskPlayerStateIsExist(); //id: Task Id

      if (formIndex < 0) {
        if (taskContent.contentResId) {
          const introContent = GetResourceValue(
            gameDocument?.gameDocument!,
            taskContent.contentResId,
            player?.playerState?.language?.name!
          );
          const hasValue = !isContentHtmlEmpty(introContent);
          isExistIntroContent = hasValue;
          setIsTaskIntro(hasValue);
        }

        if (taskContent.preMessageResId) {
          const premessageContent = GetResourceValue(
            gameDocument?.gameDocument!,
            taskContent.preMessageResId,
            player?.playerState?.language?.name!
          );
          const hasValue = !isContentHtmlEmpty(premessageContent);
          isExistPreMessageContent = hasValue;
          setIsPreMessage(hasValue);
        }
      }

      await onOpenEventTaskAsync();
    }

    //#6323 #6199 skip callout form and intro form when content is empty
    //#6541 remove callout and change it into premessage
    if (!isExistPreMessageContent && !isExistIntroContent) {
      setFormIndex(0);
      setTaskVisibility(false);
      if (
        (!taskContent?.forms || taskContent?.forms.length === 0) &&
        formIndex < 0
      ) {
        await updateTaskStatusToCompleted(player, true);
      }
    }
  };

  const getTitle = (task: TaskEntity, content?: TaskContentEntity) => {
    if (content) {
      const contentTitle = GetResourceValue(
        gameDocument?.gameDocument!,
        content?.titleResId!,
        player?.playerState?.language?.name ?? ''
      );
      if (contentTitle !== undefined && contentTitle !== '')
        return contentTitle;
    }
    return getTaskTitle(task);
  };

  const getTaskTitle = (task: TaskEntity) =>
    GetResourceValue(
      gameDocument?.gameDocument!,
      task?.titleResId!,
      player?.playerState?.language?.name ?? ''
    );

  const getQuestion = () => {
    if (formIndex > -1 && data.forms && data.forms[formIndex]) {
      const questionResId = data.forms![formIndex].questionResId;
      if (questionResId) {
        const question = GetResourceValue(
          gameDocument?.gameDocument!,
          questionResId,
          player?.playerState?.language?.name ?? ''
        );
        if (question !== undefined) {
          setQuestion(question);
        }
      }
    }
  };

  const getContent = async () => {
    if (data.contentResId) {
      const question = GetResourceValue(
        gameDocument?.gameDocument!,
        data.contentResId,
        player?.playerState?.language?.name!
      );

      if (question) {
        const result = await processMediaTags(question);
        setTaskQuestion(result);
      }
    }
  };

  const getContentPreMessage = async () => {
    if (data.preMessageResId) {
      const question = GetResourceValue(
        gameDocument?.gameDocument!,
        data.preMessageResId,
        player?.playerState?.language?.name!
      );
      if (question && isPreMessage) {
        setIsLoadingPreMessageContent(true);
        const result = await processMediaTags(question);
        setPreMessageContent(result);
        setIsLoadingPreMessageContent(false);
      }
    }
  };

  const getType = () => {
    if (data.forms && formIndex > -1) {
      const form = cloneDeep(data.forms[formIndex]);
      if (form) {
        setAnswerType(form.type);
      }
    }
  };

  const setAnswer = (formId: string) => {
    setContentAnswer([]);
    const task = GetPlayerTaskById(player, props.id);
    if (task) {
      const answer = task?.taskContentFormAnswers?.find(
        (x) => x?.formId === formId
      );

      if (answer && answer.answers) {
        setContentAnswer(cloneDeep(answer.answers));
      }
    }
  };

  const getOptions = (options: TaskContentAnswer[]) => {
    let optionsDescription: string[] = [];

    options?.forEach((opt) => {
      const description = getResourceById(opt.answerResId);
      if (description !== undefined) {
        optionsDescription.push(description);
      }
    });

    setOptions(cloneDeep(optionsDescription));
  };

  const getResourceById = (id: string) => {
    return GetResourceValue(
      gameDocument?.gameDocument!,
      id,
      player?.playerState?.language?.name!
    );
  };

  const onTaskCompletedMapAsync = async (location: string) => {
    if (location !== undefined && location !== '') {
      const steps = await getStepTaskCompletedMap(location, gameDocument);
      for (const step of steps) {
        if (props?.onAlgorithmExecuted) {
          props?.onAlgorithmExecuted(step);
        }
      }
    }
  };

  const updateTaskStatusToCompleted = async (
    player: PlayerContextState,
    isCloseTaskContent: boolean = false
  ) => {
    setPlayer((prev) => {
      const task = GetPlayerTaskById(prev, props.id);
      if (task) {
        task.status = 'completed';

        // hide task if hideWhenComplete true
        const taskGame = GetTaskGameById(gameDocument.gameDocument, props.id);
        if (taskGame && taskGame.hideWhenComplete) {
          removeTaskOverlay(props.id);
        } else {
          props.changeTaskIconToComplete(props.id);
        }

        const response = UpdateTaskAsync(prev, props.id, task!);
        response.openedTask!.length > 0 && response.openedTask!.pop();
        response.latestCompletedTaskId = props.id;
        return UpdatePlayerStateAsync(prev, response);
      }
      return prev;
    });

    await executeOnCompleteTask();

    if (isCloseTaskContent) {
      const task = gameDocument.gameDocument?.assets?.tasks?.find(
        (x) => x.id === props.id
      );
      if (task) {
        const { events: { onCloseTask: { steps = [] } = {} } = {} } = task;
        for (const step of steps) {
          if (props?.onAlgorithmExecuted) {
            props?.onAlgorithmExecuted(step);
          }
        }
      }
    }

    //Check Event Actions On Map, When Any Task is Completed
    await onTaskCompletedMapAsync(player.playerState!.location!);
  };

  const executeOnCompleteTask = async () => {
    const gameTask = gameDocument.gameDocument?.assets?.tasks?.find(
      (x) => x.id === props.id
    );
    if (gameTask) {
      const { events: { onCompleteTask: { steps = [] } = {} } = {} } = gameTask;

      const { events } = gameTask;
      for (const step of steps) {
        if (
          (!data.forms || data.forms.length > 0) &&
          step.operation === 'giveScore(score)'
        ) {
          giveScoreOnComplete(step);
        }
        if (props?.onAlgorithmExecuted) {
          props?.onAlgorithmExecuted(step);
        }
      }
    }
  };

  const nextQuestion = async () => {
    let newIndex: number = isPreMessage ? -1 : formIndex + 1;
    if (newIndex < 0) {
      setTaskVisibility(true);
    } else {
      setTaskVisibility(false);
    }
    //Task has no question then complete & close the task
    if (!data.forms || data.forms?.length === 0) {
      await updateTaskStatusToCompleted(player, true);
    } else if (newIndex > data?.forms?.length) {
      setPlayer((prev) => {
        const response = ClearOpenedTask(prev.playerState!);
        return UpdatePlayerStateAsync(prev, response);
      });
    }

    if (data && data.forms && data?.forms?.length > 0) {
      setFormIndex(newIndex);
    }

    setIsPreMessage(false);
    setIsTaskIntro(false);
    setIsFileUploaded(false);
  };

  const onLaunchHandler = async () => {
    let newIndex: number = isTaskIntro ? -1 : formIndex + 1;

    if (newIndex < 0) {
      setTaskVisibility(true);
    } else {
      setTaskVisibility(false);
    }

    if (!isTaskIntro && (!data.forms || data.forms?.length === 0)) {
      await updateTaskStatusToCompleted(player, true);
    } else if (newIndex === data?.forms?.length) {
      setPlayer((prev) => {
        const response = ClearOpenedTask(prev.playerState!);
        return UpdatePlayerStateAsync(prev, response);
      });
    }

    if (data && data.forms && data?.forms?.length > 0) {
      setFormIndex(newIndex);
    }

    setIsPreMessage(false);
    setIsTaskIntro(isTaskIntro);
    setIsFileUploaded(false);
  };

  //#6541 remove callout and change it into premessage
  const onOpenEventTaskAsync = async () => {
    const task = gameDocument.gameDocument?.assets?.tasks?.find(
      (x) => x.id === props.id
    );
    if (task) {
      const { events: { onOpenTask: { steps = [] } = {} } = {} } = task;

      for (const step of steps) {
        if (
          props?.onAlgorithmExecuted &&
          (!player.playerState?.executedStepIds?.includes(step.id) ||
            step.canExecutedMultipleTimes)
        ) {
          props?.onAlgorithmExecuted(step);
        }
      }
    }
  };

  const onCloseTaskContentHandler = async () => {
    const task = gameDocument.gameDocument?.assets?.tasks?.find(
      (x) => x.id === props.id
    );
    if (task) {
      const { events: { onCloseTask: { steps = [] } = {} } = {} } = task;
      for (const step of steps) {
        if (props?.onAlgorithmExecuted) {
          props?.onAlgorithmExecuted(step);
        }
      }
    }
    setPlayer((prev) => {
      const response = ClearOpenedTask(prev.playerState!);
      return UpdatePlayerStateAsync(prev, response);
    });
  };

  const prevQuestion = () => {
    let newIndex: number = formIndex - 1;
    if (newIndex === -1) {
      setTaskVisibility(true);
    }

    setFormIndex(newIndex);
  };

  const addManualScoring = async () => {
    const content: NotificationContent[] = notification.content;

    const form = data!.forms![formIndex];

    const answerTask = player?.playerState?.tasks?.find(
      (x) => x.id === props.id
    );

    const answer = answerTask?.taskContentFormAnswers?.find(
      (x) => x?.formId === form?.id
    );
    if (form) {
      // set message & iconType toast notification if question type manual scoring
      const manualScoringNotification: NotificationContent = {
        icon: 'notifications',
        isHide: false,
        color: 'k-button--gradient',
        message:
          generateTitleById(
            '32681cc9-9ee0-4d19-81fa-7a2b6e69a362',
            gameDocument,
            displayLanguageContext.displayLanguageSelected.resources!,
            'game'
          ) || 'Your answer has been submitted to the Facilitator for review'
      };
      content.push(manualScoringNotification);

      setNotification({
        ...notification,
        content
      });

      await addManualScoringAsync(
        gameDocument?.gameCode,
        player?.playerState?.code,
        props.id,
        form?.id,
        JSON.stringify(answer)
      );
    }
  };

  const onSubmitHandler = async () => {
    const newPlayerState = await updateAnswerToPlayerState();
    const isManualScorring = data!.forms![formIndex].isManualScoring;

    if (newPlayerState) {
      setPlayer((prev) => UpdatePlayerStateAsync(prev, newPlayerState));
      removeTaskDistance();
      const isCorrectAnswer: boolean = await checkCorrectAnswer();
      const newTask = GetPlayerTaskById(player, props.id);
      const newForm = newTask!.taskContentFormAnswers?.find(
        (x) => x?.formId === data.forms![formIndex].id
      );
      const totalScoreReduction =
        newForm?.scoringReductions?.totalScoreReduction ?? 0;
      const totalOriginalScore = getPlayerScore();
      const totalAwaredScore = totalOriginalScore - totalScoreReduction;
      const awardedScore =
        totalAwaredScore < 1 &&
        answerType !== 'checkbox' &&
        answerType !== 'radio'
          ? 0
          : Math.round(totalAwaredScore);

      if (isManualScorring) {
        await addManualScoring();
      }

      // #4505, update status to complete if player already answered all questions regardless correct or incorrect status
      const playerTasks = GetPlayerTaskById(player, props.id);
      // #4867 Multiple attempt case :
      // 1. If wrong answer and has no attempt = set task as completed
      // 2. If correct answer and has attempt = set task as completed
      // 3. If wrong answer and has attempt = task not set as competed
      if (
        data?.forms?.length === playerTasks?.taskContentFormAnswers?.length &&
        playerTasks?.taskContentFormAnswers?.findIndex(
          (x) => x.isCorrectAnswer === undefined
        ) === -1 &&
        playerTasks.status !== 'completed' &&
        playerTasks &&
        (playerTasks.taskContentFormAnswers![formIndex].isCorrectAnswer ||
          (!playerTasks.taskContentFormAnswers![formIndex].isCorrectAnswer &&
            playerTasks.taskContentFormAnswers![formIndex].attemptsLeft! <= 0))
      ) {
        if (!isManualScorring) {
          if (answerType === 'checkbox') {
            getCheckboxMessage(
              totalOriginalScore,
              awardedScore,
              isCorrectAnswer
            );
          } else {
            if (answerType !== 'textarea' && answerType !== 'none') {
              getFeedbackMessage(
                totalOriginalScore,
                awardedScore,
                isCorrectAnswer
              );
            }
          }
        }
        await updateTaskStatusToCompleted(player);
      } else {
        if (answerType === 'checkbox') {
          if (
            getPlayerAttemptsLeft() <= 0 ||
            getCheckboxScore(data.forms![formIndex]) ===
              data!.forms![formIndex]?.score! ||
            (playerTasks?.taskContentFormAnswers![formIndex]
              .isScoringReduction &&
              getPlayerAttemptsLeft() <= 0)
          ) {
            if (!isManualScorring) {
              getCheckboxMessage(
                totalOriginalScore,
                awardedScore,
                isCorrectAnswer
              );
            }
            setTimeout(nextQuestion, 500);
          }
        } else {
          if (isCorrectAnswer || getPlayerAttemptsLeft() === -1) {
            if (answerType !== 'textarea' && answerType !== 'none') {
              if (!isManualScorring) {
                getFeedbackMessage(
                  totalOriginalScore,
                  awardedScore,
                  isCorrectAnswer
                );
              }
            }
            setTimeout(nextQuestion, 500);
          }
        }
      }
    }
  };

  /**
   * Get checkbox message (feedback)
   * @param awardedScore
   */
  const getCheckboxMessage = (
    totalOriginalScore: number,
    awardedScore: number,
    isCorrectAnswer: boolean
  ) => {
    const newPlayerState = updatePlayerAnswerScore(player, awardedScore);
    if (newPlayerState) {
      // Update answer score selected to player state
      setPlayer((prev) => {
        return UpdatePlayerStateAsync(prev, newPlayerState);
      });
    }

    let customFeedback = GetCustomFeedback(
      gameDocument.gameDocument,
      data.id,
      formIndex
    );

    if (customFeedback) {
      // temporary note: "isCorrectAnswer" is a trigger to display a popup of the correct answer.
      if (totalOriginalScore && isCorrectAnswer) {
        const playerAnswers = contentAnswer.filter((x) => x.value as boolean);

        const correctOptions = getQuestionCorrectAnswers().map(
          (x) => x.answerResId
        );

        const checkedCorrectOptions = playerAnswers.filter((x) =>
          correctOptions.includes(x.answerResId)
        ).length;

        const isPartialCorrect =
          correctOptions.length !== checkedCorrectOptions;

        const url = GetResourceValue(
          gameDocument?.gameDocument!,
          isPartialCorrect
            ? customFeedback?.partiallyCorrectUrlResId!
            : customFeedback?.allCorrectUrlResId!,
          ''
        );

        const isDefault = isPartialCorrect
          ? customFeedback.isDefaultPartiallyCorrect
          : customFeedback.isDefaultAllCorrect;

        const message = isDefault
          ? defaultMessage(
              isPartialCorrect
                ? 'defaultPartialFeedbackMessageResId'
                : 'defaultAllCorrectFeedbackMessageResId'
            )
          : GetResourceValue(
              gameDocument?.gameDocument!,
              isPartialCorrect
                ? customFeedback?.partiallyCorrectMessageResId!
                : customFeedback?.allCorrectMessageResId!,
              player?.playerState?.language?.name
            );

        showFeedbackToast(
          message,
          !url || url === ''
            ? imageUrl('defaultFeedbackAllCorrectIconResId')
            : url,
          true,
          awardedScore
        );
      } else {
        const url = GetResourceValue(
          gameDocument?.gameDocument!,
          customFeedback?.allWrongUrlResId!,
          ''
        );

        const message = customFeedback.isDefaultAllWrong
          ? defaultMessage('defaultAllWrongFeedbackMessageResId')
          : GetResourceValue(
              gameDocument?.gameDocument!,
              customFeedback?.allWrongMessageResId!,
              player?.playerState?.language?.name
            );

        showFeedbackToast(
          message,
          !url || url === ''
            ? imageUrl('defaultFeedbackAllWrongIconResId')
            : url,
          false
        );
      }
    }
  };

  const isWarningIcon = (awardedScore: number) => {
    let result = true;
    const formAnswer = data.forms![formIndex].answers;
    if ((answerType === 'radio' || answerType === 'checkbox') && formAnswer) {
      const correctAnswer = contentAnswer?.find((i) =>
        formAnswer!.some(
          (answer) => answer.isChecked && answer.answerResId === i.answerResId
        )
      );
      result = correctAnswer !== undefined || correctAnswer !== null;
    } else if (awardedScore > 0) {
      result = false;
    }
    return result;
  };

  const imageUrl = useCallback(
    (
      res:
        | 'defaultFeedbackAllCorrectIconResId'
        | 'defaultFeedbackAllWrongIconResId'
    ) => {
      if (
        gameDocument.gameDocument?.settings &&
        gameDocument.gameDocument?.settings.designer
      ) {
        const feedbackIcon = GetResourceValue(
          gameDocument.gameDocument!,
          gameDocument.gameDocument?.settings.designer[res],
          ''
        );
        return feedbackIcon !== '' ? feedbackIcon : defaultImage[res];
      } else {
        return res === 'defaultFeedbackAllCorrectIconResId'
          ? defaultImage.defaultFeedbackAllCorrectIconResId
          : defaultImage.defaultFeedbackAllWrongIconResId;
      }
    },
    [gameDocument.gameDocument?.settings]
  );

  const defaultMessage = React.useCallback(
    (
      type:
        | 'defaultAllCorrectFeedbackMessageResId'
        | 'defaultAllWrongFeedbackMessageResId'
        | 'defaultPartialFeedbackMessageResId'
    ) => {
      if (type === 'defaultPartialFeedbackMessageResId') {
        return defaultFeedbackMessage[type];
      }

      if (
        gameDocument.gameDocument?.settings &&
        gameDocument.gameDocument?.settings.designer
      ) {
        const message = GetResourceValue(
          gameDocument.gameDocument,
          gameDocument.gameDocument.settings.designer[type],
          ''
        );

        const value = message == '' ? defaultFeedbackMessage[type] : message;
        return value;
      }

      return defaultFeedbackMessage[type];
    },
    [gameDocument.gameDocument?.settings]
  );

  const giveScoreOnComplete = (step: AlgorithmStep) => {
    const playerTask = GetPlayerTaskById(player, props.id);
    const awardedScore = playerTask?.taskContentFormAnswers?.reduce(
      (total, item) => total + (item.score ?? 0),
      0
    );

    const scoreToAward = step.useFallbackValue
      ? awardedScore
      : step.argumentQuantity;

    if (step.identifier === 'player') {
      setPlayer((prev) => {
        const response = GiveScore(prev, scoreToAward!);
        return UpdatePlayerStateAsync(prev, response);
      });

      if (awardedScore !== 0) {
        showScoreNotification(scoreToAward!);
      }
    } else if (step.identifier === 'team') {
      teamGiveScore(
        gameDocument?.gameCode!,
        player.playerState?.teamCode!,
        scoreToAward!,
        true
      );
    }
  };

  const showScoreNotification = (awardedScore: number) => {
    const content: NotificationContent[] = notification.content;
    // set message & icon type toast notification
    let message: string = '';

    /**
     * to display the score in the notification.
     * Make sure the translated results have a % sign, so the value can be replaced with a dynamic value.
     * There may be a human error when filling in the display language administrator
     */

    const pointAwardedTitle =
      generateTitleById(
        'cea1d18b-4808-4c1f-8d46-88edf44ba525',
        gameDocument,
        displayLanguageContext.displayLanguageSelected.resources!,
        'game'
      ) || 'points awarded for your correct answers!';

    message = `${awardedScore} ${pointAwardedTitle}`;

    const scoreNotification: NotificationContent = {
      icon: isWarningIcon(awardedScore) ? 'warning' : 'notifications',
      isHide: false,
      color: 'k-button--gradient',
      message
    };
    content.push(scoreNotification);

    setNotification({
      ...notification,
      content
    });
  };

  /**
   * Get feedback message
   * @param awardedScore
   */
  const getFeedbackMessage = (
    totalOriginalScore: number,
    awardedScore: number,
    isCorrectAnswer: boolean
  ) => {
    let questionScore = data!.forms![formIndex]!.score! ?? 0;
    if (answerType === 'radio') {
      // * Function to add up the scores of all correct answers
      const scoreCorrectAnswer = getQuestionCorrectAnswers().map(
        (x) => x.score ?? 0
      );
      questionScore = CalculateTotal(scoreCorrectAnswer);
    }

    const newPlayerState = updatePlayerAnswerScore(player, awardedScore);
    if (newPlayerState) {
      // Update answer score selected to player state
      setPlayer((prev) => {
        return UpdatePlayerStateAsync(prev, newPlayerState);
      });
    }

    const customFeedback = GetCustomFeedback(
      gameDocument.gameDocument,
      data.id,
      formIndex
    );

    if (customFeedback) {
      // for answerType other than 'file', 'image' and 'video', then check the score
      // for answerType 'file', 'image' and 'video', then check isFileUploaded

      // temporary note: "isCorrectAnswer" is a trigger to display a popup of the correct answer.

      if (
        (answerType !== 'file' &&
          answerType !== 'image' &&
          answerType !== 'video' &&
          totalOriginalScore &&
          isCorrectAnswer) ||
        isFileUploaded
      ) {
        const url = GetResourceValue(
          gameDocument?.gameDocument!,
          customFeedback?.allCorrectUrlResId!,
          ''
        );

        const message = GetResourceValue(
          gameDocument?.gameDocument!,
          customFeedback?.allCorrectMessageResId!,
          player?.playerState?.language?.name
        );

        showFeedbackToast(
          message && message !== ''
            ? message
            : customFeedback
              ? defaultMessage('defaultAllCorrectFeedbackMessageResId')
              : '',
          !url || url === ''
            ? imageUrl('defaultFeedbackAllCorrectIconResId')
            : url,
          true,
          awardedScore
        );
      } else {
        const url = GetResourceValue(
          gameDocument?.gameDocument!,
          customFeedback?.allWrongUrlResId!,
          ''
        );

        const message = GetResourceValue(
          gameDocument?.gameDocument!,
          customFeedback?.allWrongMessageResId!,
          player?.playerState?.language?.name
        );

        showFeedbackToast(
          message && message !== ''
            ? message
            : customFeedback
              ? defaultMessage('defaultAllWrongFeedbackMessageResId')
              : '',
          !url || url === ''
            ? imageUrl('defaultFeedbackAllWrongIconResId')
            : url,
          false
        );
      }
    }
  };

  /**
   * Show chechbox answer toast
   * @param message
   * @param imageUrl
   */
  const showFeedbackToast = (
    message: string,
    imageUrl: string,
    isAllCorrect: boolean,
    score?: number
  ) => {
    const addItemNotification: AchievementContent = {
      isHide: false,
      imageUrl: imageUrl,
      priorityQueueNumber: 2,
      message: (
        <>
          <div className={'d-flex justify-content-center flex-column h-100'}>
            <div
              className={
                'd-flex flex-column align-items-center justify-content-center mb-auto p-3'
              }>
              {score && (
                <span>{`${score} ${score > 1 ? 'Points' : 'Point'}`}</span>
              )}
              <span className={'fw-bold'} style={{ fontSize: '20px' }}>
                {isAllCorrect ? 'Excellent!' : 'Unlucky.'}
              </span>
            </div>
            <div className={'d-flex justify-content-center mb-auto p-3'}>
              {message}
            </div>
          </div>
        </>
      )
    };
    const content: AchievementContent[] = toast.content;
    content.push(addItemNotification);
    setToast({ ...toast, content });
  };

  const checkCorrectAnswer = async (): Promise<boolean> => {
    let isAnswerCorrect = true;

    if (validateScoring()) {
      if (answerType === 'text') {
        //Check default answer has been set
        let acceptableAnswersData: string = '';
        const taskAnswer = data!.forms![formIndex];
        if (taskAnswer && taskAnswer.answers) {
          acceptableAnswersData = getResourceById(
            taskAnswer.answers[0].answerResId
          );
        }

        //compare player answer with acceptable answer
        if (!isEmpty(acceptableAnswersData)) {
          // check is acceptable answer is using wildcard or not
          const isWildcard = (answer: string) =>
            new RegExp(`^\\.\\*.*${answer}.*\\.\\*$`).test(answer);

          const answer = contentAnswer[0]?.value;
          // split multiple answer using |
          const patternMap = acceptableAnswersData
            .split('|')
            .map((acceptableAnswer) => {
              // if using wildcard, return regex instance
              return isWildcard(acceptableAnswer)
                ? new RegExp(
                    acceptableAnswer
                      ? acceptableAnswer.toLowerCase()
                      : acceptableAnswer
                  )
                : acceptableAnswer;
            });

          // Try to match the answer with acceptable answer using
          isAnswerCorrect = patternMap.some((pattern) =>
            pattern instanceof RegExp
              ? pattern.test(answer ? answer.toLowerCase() : answer)
              : pattern === answer
          );
        } else {
          //Task has no default answer, set it correct by default
          isAnswerCorrect = true;
        }
      } else if (
        answerType === 'textarea' ||
        answerType === 'date' ||
        answerType === 'time'
      ) {
        //Check default answer has been set
        let acceptableAnswers: string = '';
        const taskAnswer = data!.forms![formIndex];
        if (taskAnswer && taskAnswer.answers) {
          acceptableAnswers = getResourceById(
            taskAnswer.answers[0].answerResId
          );
        }

        //compare player answer with acceptable answer
        if (!isEmpty(acceptableAnswers)) {
          isAnswerCorrect =
            contentAnswer.length > 0 &&
            contentAnswer[0]?.value?.toLowerCase() ===
              acceptableAnswers?.toLowerCase();
        } else {
          //Task has no default answer, set it correct by default
          isAnswerCorrect = true;
        }
      } else if (answerType === 'number') {
        //Check default answer has been set
        let acceptableAnswers: string = '';
        const taskAnswer = data!.forms![formIndex];
        if (taskAnswer && taskAnswer.answers) {
          acceptableAnswers = getResourceById(
            taskAnswer.answers[0].answerResId
          );
        }

        //compare player answer with acceptable answer
        if (!isEmpty(acceptableAnswers)) {
          isAnswerCorrect =
            contentAnswer.length > 0 &&
            parseInt(contentAnswer[0]?.value?.toString()) ===
              parseInt(acceptableAnswers);
        } else {
          //Task has no default answer, set it correct by default
          isAnswerCorrect = true;
        }
      } else if (answerType === 'checkbox') {
        if (validateScoring()) {
          isAnswerCorrect = checkboxAnswerCheckHandler(data.forms![0]!);
        }
      } else if (answerType === 'radio') {
        const correctAnswer = data.forms![formIndex].answers?.find(
          (x) => x.isChecked
        );
        const playerAnswer = contentAnswer.find((x) => x.value as boolean);
        isAnswerCorrect =
          contentAnswer.length > 0 &&
          correctAnswer?.answerResId === playerAnswer?.answerResId;
      } else if (answerType === 'image' || answerType === 'video') {
        isAnswerCorrect = contentAnswer.length > 0;
      }
    }

    const task = GetPlayerTaskById(player, props.id);

    if (task) {
      const form = cloneDeep(
        task.taskContentFormAnswers?.find(
          (x) => x?.formId === data.forms![formIndex].id
        )
      );
      if (!isAnswerCorrect) {
        if (form && form.score !== undefined) {
          form.score =
            form.score - (data.forms![formIndex]?.pointDepreciation ?? 0);
        }
        // #6529 If attempsLeft is finished and the answer is still wrong, the score will immediately be changed to 0
        if (form && form.attemptsLeft && form.attemptsLeft - 1 <= 0) {
          form.score = 0;
        }
        if (form && form.attemptsLeft && form.attemptsLeft > 0) {
          form.attemptsLeft = form.attemptsLeft - 1;
        }
      }

      if (form && form.isCorrectAnswer !== undefined) {
        form.isCorrectAnswer = isAnswerCorrect;
      }

      task.taskContentFormAnswers![formIndex!] = form!;

      setPlayer((prev) => {
        const response = UpdateTaskAsync(prev, props.id, task);
        return UpdatePlayerStateAsync(prev, response);
      });
    }

    return isAnswerCorrect;
  };

  const getPlayerAttemptsLeft = (): number => {
    if (formIndex < 0) return -1;
    if (player.playerState && data.forms && props.id) {
      return (
        GetPlayerTaskById(player, props.id)?.taskContentFormAnswers?.find(
          (x) => x?.formId === data.forms![formIndex]?.id
        )?.attemptsLeft ?? -1
      );
    }
    return -1;
  };

  const getPlayerAnswerStatus = (): boolean | undefined => {
    if (formIndex < 0) return;
    if (player.playerState && data.forms && props.id) {
      return (
        GetPlayerTaskById(player, props.id)?.taskContentFormAnswers?.find(
          (x) => x?.formId === data.forms![formIndex]?.id
        )?.isCorrectAnswer ?? undefined
      );
    }
    return undefined;
  };

  const getPlayerScore = (): number => {
    if (formIndex < 0) return -1;
    if (player.playerState && data.forms && props.id) {
      if (answerType !== 'checkbox' && answerType !== 'radio') {
        return (
          GetPlayerTaskById(player, props.id)?.taskContentFormAnswers?.find(
            (x) => x?.formId === data.forms![formIndex]?.id
          )?.score ?? 0
        );
      } else {
        // #6695
        //new behaviour calculate player score when answer type is checkbox or radio
        let playerAnswers = contentAnswer.filter((x) => x.value as boolean);

        const score =
          answerType === 'radio'
            ? calculateAnswerWithScore(playerAnswers)
            : getCheckboxScore(data.forms[formIndex]);

        return score;
      }
    }
    return 0;
  };

  /**
   * Get answer score of question
   * @param form
   * @returns
   */
  const getAnswerScore = (form: TaskContentForm) => {
    if (form) {
      if (form.type === 'checkbox') {
        return getCheckboxScore(form);
      } else if (form.type === 'radio') {
        let playerAnswers = contentAnswer.filter((x) => x.value as boolean);
        return calculateAnswerWithScore(playerAnswers);
      }

      return form.score ?? 0;
    }
  };

  /**
   * Calculate Player Score for answer type checkbox and radio
   */
  const calculateAnswerWithScore = (playerAnswers: ContentAnswer[]) => {
    const playerContentAnswers = data.forms![formIndex].answers?.filter(
      (answer) =>
        playerAnswers.some((i) => i.answerResId === answer.answerResId)
    );

    const calculateScore = playerContentAnswers?.reduce((total, item) => {
      const score =
        item?.score && typeof item.score === 'number' ? item.score : 0;
      return total + score;
    }, 0);
    return calculateScore ?? 0;
  };

  /**
   * Get & calculate checkbox score
   * @returns
   */
  const getCheckboxScore = (form: TaskContentForm) => {
    if (form) {
      let playerAnswers = contentAnswer.filter((x) => x.value as boolean);
      let score = calculateAnswerWithScore(playerAnswers);

      return score;
    }
    return 0;
  };

  /**
   * Get correct checkbox answer
   * @returns boolean
   */
  const checkboxAnswerCheckHandler = (form: TaskContentForm) => {
    if (form) {
      const playerAnswers = contentAnswer.filter((x) => x.value as boolean);

      const correctOptions = getQuestionCorrectAnswers().map(
        (x) => x.answerResId
      );

      const wrongOptions = form.answers
        ?.filter((i) => !i.isChecked)
        ?.map((i) => i.answerResId);

      const checkedCorrectOptions = playerAnswers.filter((x) =>
        correctOptions.includes(x.answerResId)
      ).length;

      const checkedWrongOptions = wrongOptions
        ? playerAnswers.filter((x) => wrongOptions.includes(x.answerResId))
            .length
        : 0;

      return (
        (checkedWrongOptions === 0 || !checkedWrongOptions) &&
        checkedCorrectOptions !== 0
      );
    }
    return false;
  };

  /**
   * Validate the question is able to score or not
   * @returns
   */
  const validateScoring = () => {
    const playerAttemptsLeft = getPlayerAttemptsLeft();

    // Task has manual scoring, no need to check for acceptable answers
    const isManualScorring = data!.forms![formIndex].isManualScoring;
    return (
      data &&
      data.forms &&
      formIndex > -1 &&
      playerAttemptsLeft !== 0 &&
      !isManualScorring
    );
  };

  /**
   * Get correct answers of question
   * @returns
   */
  const getQuestionCorrectAnswers = () => {
    if (validateScoring()) {
      return data.forms![formIndex].answers?.filter((x) => x.isChecked) ?? [];
    }
    return [];
  };

  const updateAnswerToPlayerState = async () => {
    if (player.playerState && data.forms) {
      const formId = data.forms[formIndex]?.id;
      let task = GetPlayerTaskById(player, props.id);

      if (task) {
        let isExistAnswer = task?.taskContentFormAnswers?.find(
          (x) => x?.formId === formId
        );
        const isScoringReduction = data.forms![formIndex].isScoringReduction;

        if (isExistAnswer && !isExistAnswer.isScoringReduction) {
          task.taskContentFormAnswers![formIndex].answers =
            cloneDeep(contentAnswer);
        } else {
          if (isScoringReduction) {
            task.taskContentFormAnswers![formIndex].answers =
              cloneDeep(contentAnswer);
            task.taskContentFormAnswers![formIndex].isCorrectAnswer = true;
            task.taskContentFormAnswers![formIndex].attemptsLeft = task
              .taskContentFormAnswers![formIndex].attemptsLeft
              ? task.taskContentFormAnswers![formIndex].attemptsLeft
              : data.forms[formIndex].maxAttempts
                ? data.forms[formIndex].maxAttempts
                : -1;
            task.taskContentFormAnswers![formIndex].score = getAnswerScore(
              data.forms[formIndex]
            );
          } else {
            if (!isExistAnswer) {
              let newForm: TaskContentAnswerEntity = {
                formId: formId,
                answers: cloneDeep(contentAnswer),
                attemptsLeft: data.forms[formIndex]?.maxAttempts ?? -1,
                isCorrectAnswer: true,
                score: getAnswerScore(data.forms[formIndex])
              };

              if (!task.taskContentFormAnswers) {
                task.taskContentFormAnswers = [];
              }
              task.taskContentFormAnswers?.push(newForm);
            }
          }
        }

        return UpdateTaskAsync(player, props.id, task);
      }
    }
  };

  //update task answer player state
  const updatePlayerAnswerScore = (
    playerContext: PlayerContextState,
    awardedScore: number
  ) => {
    if (playerContext && data.forms) {
      const formId = data.forms[formIndex]?.id;
      const task = GetPlayerTaskById(playerContext, props.id);
      let taskContentAnswer = task?.taskContentFormAnswers?.find(
        (i) => i.formId === formId
      );
      if (taskContentAnswer) {
        taskContentAnswer.score = awardedScore;
      }
      return UpdateTaskAsync(playerContext, props.id, task!);
    }
  };

  const onCheckboxChangeHandler = React.useCallback(
    (index: number, value: boolean) => {
      const form = cloneDeep(data?.forms![formIndex]);
      if (form) {
        const answerResId = form.answers
          ? form.answers[index]?.answerResId
          : '';
        let newContentAnswer: ContentAnswer[] = cloneDeep(contentAnswer);

        if (answerResId !== '') {
          const answerIndex = newContentAnswer?.findIndex(
            (x) => x.answerResId === answerResId
          );

          if (answerIndex > -1) {
            newContentAnswer[answerIndex].value = value;
            newContentAnswer[answerIndex].score = form.answers
              ? form.answers[answerIndex]?.score
              : 0;
          }

          if (answerIndex === -1) {
            const score = form.answers ? form.answers[answerIndex]?.score : 0;

            newContentAnswer.push({
              answerResId: answerResId,
              value: value,
              score
            });
          }

          if (value) {
            setTotalTryAnswer(totalTryAnswer + 1);
          } else {
            setTotalTryAnswer(totalTryAnswer - 1);
            //if player uncheck answer, remove answer from content answer
            newContentAnswer.splice(answerIndex, 1);
          }

          setContentAnswer(cloneDeep(newContentAnswer));
        }
      }
    },
    [contentAnswer]
  );

  //On input change (single input like Text, Textarea, Date, Number)
  const onSingleInputChangeAnswer = (
    value: string | number | File,
    size?: number
  ) => {
    if (typeof value === 'string' || typeof value === 'number') {
      setIsFileUploaded(false);
      if (typeof value === 'string' && value.startsWith('blob:')) {
        setIsLoading(true);
        UploadSingleBlobAsync(gameDocument?.gameCode!, value)
          .then((res) => {
            if (res !== undefined) {
              updateAnswerValue(res.url, size);
            }
          })
          .catch((error) => console.error(error))
          .finally(() => {
            setIsLoading(false);
            setIsFileUploaded(true);
          });
      } else {
        updateAnswerValue(value as string | number, size);
      }
    } else {
      setIsLoading(true);

      UploadSingleFileAsync(gameDocument?.gameCode!, value as File)
        .then((res) => {
          if (res !== undefined) {
            updateAnswerValue(res.url, size);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setIsLoading(false);
          setIsFileUploaded(true);
        });
    }
  };

  const updateAnswerValue = (
    value: string | number | undefined,
    size?: number
  ) => {
    if (contentAnswer.length === 0) {
      let newAnswer: ContentAnswer[] = [];
      const form = cloneDeep(data?.forms![formIndex]);
      if (form) {
        form?.answers?.forEach((answer) => {
          newAnswer.push({
            answerResId: answer?.answerResId,
            value: value,
            size: size,
            score: answer?.score
          });
        });
        setContentAnswer(cloneDeep(newAnswer));
      }
    } else {
      let newAnswer: ContentAnswer[] = cloneDeep(contentAnswer);
      newAnswer[0].value = value;
      newAnswer[0].size = size;
      setContentAnswer(cloneDeep(newAnswer));
    }
  };

  //On radio button index change
  const onRadioChangeAnswer = (value: number) => {
    let newAnswer: ContentAnswer[] = [];
    const form = cloneDeep(data?.forms![formIndex]);
    if (form) {
      form?.answers?.forEach((answer, index) => {
        newAnswer.push({
          answerResId: answer?.answerResId,
          value: (index === value) as boolean,
          score: answer?.score
        });
      });
      setContentAnswer(cloneDeep(newAnswer));
    }
  };

  const checkTaskPlayerStateIsExist = async () => {
    if (player.playerState) {
      const taskPlayer = GetPlayerTaskById(player, props.id);
      //If not exist then add
      if (!taskPlayer) {
        let newTask: TaskEntityPlayer = {
          id: props.id,
          isVisible: true,
          taskContentId: props.id,
          taskContentFormAnswers: []
        };

        setPlayer((prev) => {
          const response = AddTask(prev, newTask);
          return UpdatePlayerStateAsync(prev, response);
        });
      }
    }
  };

  const repopulateQuestion = () => {
    getType();
    getQuestion();
    const forms = cloneDeep(data.forms);
    if (forms && forms[formIndex]) {
      const form = cloneDeep(forms[formIndex]);
      getOptions(form.answers ?? []);
      setSelectedCheckboxOptions(cloneDeep(form.answers ?? []));
      setAnswer(form.id);
    }
  };

  const setSelectedCheckboxOptions = (answers: TaskContentAnswer[]) => {
    if (answerType === 'checkbox') {
      if (formIndex > -1) {
        let option: CheckboxOption[] = [];
        if (answers) {
          answers?.forEach((answer) => {
            const description = getResourceById(answer.answerResId);
            option.push({
              answerResId: answer.answerResId,
              description: description ?? '',
              isChecked:
                (contentAnswer?.find(
                  (x) => x.answerResId === answer?.answerResId
                )?.value as boolean) ?? false
            });
          });

          setCheckboxOption(cloneDeep(option));
        }
      }
    }
  };

  const renderPlayerAttempts = (): JSX.Element => {
    const attemptsLeft: number = getPlayerAttemptsLeft();
    if (data?.forms) {
      const maxAttempts: number =
        formIndex > -1 ? (data?.forms![formIndex]?.maxAttempts ?? -1) : -1;

      if (attemptsLeft > 0 && attemptsLeft < maxAttempts) {
        return (
          <>
            {(
              generateTitleById(
                '298f372e-f97b-42c6-b1a7-a0bf7866ec7b',
                gameDocument,
                displayLanguageContext.displayLanguageSelected.resources!,
                'game'
              ) || 'Try again - % attempt(s) remaining'
            ).replace('%', attemptsLeft.toString())}
          </>
        );
      }
    }
    return <></>;
  };

  const timeReduction = (scoreReduction: ScoringReductionEntity) => {
    let cloneScoreReduction = { ...scoreReduction };
    const maxTimeLimit = cloneScoreReduction?.maximumTimeLimit! - 1;
    cloneScoreReduction.maximumTimeLimit = maxTimeLimit;
    return cloneScoreReduction;
  };

  const isDisabledAnswer = () => {
    const isTimeRemaining = isScoringReduction
      ? questionScoringReduction!.maximumTimeLimit! < 1
      : false;

    return (
      ((getPlayerAttemptsLeft() === 0 ||
        getPlayerAnswerStatus() === true ||
        (getPlayerAttemptsLeft() === -1 &&
          getPlayerAnswerStatus() === false)) &&
        !taskVisibility) ||
      isTimeRemaining
    );
  };

  const isLockToAnswer = (): boolean => {
    if (data && data.forms) {
      if (answerType === 'checkbox') {
        let totalCorrectAnswers =
          data.forms[formIndex].answers?.filter((x) => x.isChecked).length ?? 0;
        const isTimeRemaining = data?.forms[formIndex].isScoringReduction
          ? questionScoringReduction!.maximumTimeLimit! < 1
          : false;
        if (totalTryAnswer >= totalCorrectAnswers || isTimeRemaining) {
          return true;
        }
      }
    }

    return false;
  };

  const resetTaskContent = async () => {
    setFormIndex(-1);
    setQuestion('');
    setOptions([]);
    setAnswerType('none');
    setTaskQuestion('');
    setTaskVisibility(true);
    setContentAnswer([]);
    setCheckboxOption([]);
    setTotalTryAnswer(0);
    setQuestionScoringReduction({});
    setCounterTimeInterval(1);
  };

  const onSubmitMediaHandler = () => {
    setShowMediaSubmitConfirmation(true);
  };

  const generatePopupMessage = (answerTypeProps?: AnswerType): string => {
    let message = '';
    if (answerTypeProps === 'image') {
      message =
        generateTitleById(
          '42b77164-cdf8-4222-94ad-4e5652b6b2f8',
          gameDocument,
          displayLanguageContext.displayLanguageSelected.resources!
        ) || 'No photo submitted. Please try again.';
    } else if (answerTypeProps === 'video') {
      message =
        generateTitleById(
          '4981eed4-5e4a-4f90-819e-385c7634a48e',
          gameDocument,
          displayLanguageContext.displayLanguageSelected.resources!
        ) || 'No video submitted. Please try again.';
    }
    return message;
  };

  const getScoringReduction = async () => {
    if (player.playerState && data.forms) {
      const formId = data.forms[formIndex]?.id;
      setIsScoringReduction(data.forms[formIndex].isScoringReduction ?? false);
      let task = GetPlayerTaskById(player, props.id);
      if (task) {
        const newPlayerState = await setPlayerScoreReduction(
          player,
          props.id,
          data.forms,
          formIndex,
          task,
          formId
        );
        if (newPlayerState) {
          setPlayer((prev) => UpdatePlayerStateAsync(prev, newPlayerState));
          let isExistAnswer = task?.taskContentFormAnswers?.find(
            (x: TaskContentAnswerEntity) => x?.formId === formId
          );

          if (isExistAnswer) {
            const currentTaskContentFormAnswers =
              task?.taskContentFormAnswers![formIndex];
            setQuestionScoringReduction(
              currentTaskContentFormAnswers.scoringReductions
            );
          }
        }
      }
    }
  };

  const getMaxTimeLimit = () => {
    if (formIndex >= 0) {
      let task = GetPlayerTaskById(player, props.id);

      if (task?.taskContentFormAnswers) {
        const formAnswer = task?.taskContentFormAnswers![formIndex];

        const isStillTime =
          task && formAnswer && isScoringReduction
            ? (formAnswer?.scoringReductions?.maximumTimeLimit ?? 0) > 0
            : false;
        return isStillTime ? 1000 : null;
      }
    }
    return null;
  };

  // hidden close, previous, and next button if
  // question with timer
  // timer still running
  // and not answered yet
  const isUnableToCloseOrChangeForm = useMemo(() => {
    const isAnsweredQuestion = getPlayerAnswerStatus();
    if (
      formIndex > -1 &&
      isAnsweredQuestion === undefined &&
      data.forms &&
      questionScoringReduction?.maximumTimeLimit &&
      questionScoringReduction.maximumTimeLimit > 0
    ) {
      const currentQuestionForm = data.forms[formIndex];
      return currentQuestionForm.isScoringReduction;
    }

    return false;
  }, [formIndex, data.forms, questionScoringReduction]);

  useInterval(() => {
    if (isDisabledAnswer()) {
      return;
    }
    let task = GetPlayerTaskById(player, props.id);

    let newQuestionScoring = timeReduction(
      task?.taskContentFormAnswers![formIndex].scoringReductions!
    );
    if (newQuestionScoring) {
      if (counterTimeInterval === newQuestionScoring.scoreReductionInterval) {
        newQuestionScoring.totalScoreReduction =
          newQuestionScoring.totalScoreReduction! +
          newQuestionScoring.scoreReduction!;
        setCounterTimeInterval(1);
      } else {
        setCounterTimeInterval(counterTimeInterval + 1);
      }
      setQuestionScoringReduction(newQuestionScoring);
      setPlayer((prev) => {
        task!.taskContentFormAnswers![formIndex].scoringReductions! =
          newQuestionScoring;

        let response = UpdateTaskAsync(prev, props.id, task!);
        return UpdatePlayerStateAsync(prev, response);
      });
    }
  }, getMaxTimeLimit());

  // Move the getTaskContent function to run first
  useEffect(() => {
    resetTaskContent();

    // eslint-disable-next-line
  }, [props.id]);

  useEffect(() => {
    setTotalTryAnswer(0);
    (async function fetchData() {
      await getContentPreMessage();
      await getContent();

      if (formIndex > -1) {
        if (data?.forms && data?.forms[formIndex]?.isScoringReduction) {
          await getScoringReduction();
        }
        repopulateQuestion();
      }
    })();
    // eslint-disable-next-line
  }, [formIndex, data]);

  useEffect(() => {
    if (formIndex == -1) {
      getTaskContent(props.id);
      hasMounted.current = true;
    }

    setIsScoringReduction(false);
  }, [formIndex]);

  useEffect(() => {
    if (formIndex > -1 && data && data.forms) {
      setSelectedCheckboxOptions(
        cloneDeep(data.forms[formIndex]?.answers ?? [])
      );
    }
    // eslint-disable-next-line
  }, [contentAnswer]);

  useEffect(() => {
    const task = GetPlayerTaskById(player, props.id);

    if (task?.isForceComplete && task?.status !== 'completed') {
      onSubmitHandler();
    }

    if (task?.status === 'incomplete' && !task.isForceComplete) {
      props.changeTaskIconToInComplete(task?.id!);
    }
  }, [player.playerState, props.id]);

  const theme = gameDocument.theme?.colors ?? [];

  useEffect(() => {
    if (theme) {
      const primaryColor = theme.find((x) => x.type === 'Primary');
      const taskContentHeader = document.querySelector('.content__header');

      if (taskContentHeader) {
        if (!primaryColor)
          (taskContentHeader as HTMLElement).style.backgroundColor =
            'rgb(70,152,203)';

        if (primaryColor) {
          const { color } = primaryColor;
          (taskContentHeader as HTMLElement).style.backgroundColor = color;
        }
      }
    }
  }, [theme, isPreMessage, taskVisibility]);

  return isPreMessage ? (
    <div className={`pre-message-dialog__mask`}>
      <div className={`pre-message-dialog`}>
        <div className={`pre-message-dialog__body overflow-auto`}>
          {isLoadingPreMessageContent ? (
            <div className={`d-flex`} style={{ marginTop: 'unset' }}>
              <Loader size="large" type="infinite-spinner" />
            </div>
          ) : (
            <>
              {!props.isAccesible && (
                <div className="alert alert-warning" role="alert">
                  You must be in the range
                  {` ${gameDocument.gameDocument?.settings.inGame.setTaskProximity ?? 0} meters`}
                </div>
              )}

              {data.bodyType === 'HTML' ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: preMessageContent ?? ''
                  }}
                />
              ) : (
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {preMessageContent ?? ''}
                </ReactMarkdown>
              )}
              <div className={'d-flex'}>
                <Button
                  id={'btn-task-content-premessage-close'}
                  themeColor={'secondary'}
                  fillMode={'outline'}
                  className={'mt-4 mr-4'}
                  onClick={onCloseTaskContentHandler}>
                  {generateTitleById(
                    '48bc820b-40e8-4624-8d46-b68e16013be6',
                    gameDocument,
                    displayLanguageContext.displayLanguageSelected.resources!,
                    'game'
                  ) || 'Close'}
                </Button>
                {props.isAccesible && (
                  <Button
                    id={'btn-task-content-premessage-continue'}
                    themeColor={'success'}
                    fillMode={'solid'}
                    className={'mt-4'}
                    onClick={onLaunchHandler}
                    disabled={isDisabledAnswer()}>
                    {generateTitleById(
                      'be998642-6834-40d7-b457-55acafbe0fc0',
                      gameDocument,
                      displayLanguageContext.displayLanguageSelected.resources!,
                      'game'
                    ) || 'Continue'}
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  ) : isTaskIntro || (data && data.forms && data.forms.length > 0) ? (
    <div className={'task-content__mask'}>
      <Container className={'task-content px-0'} fluid>
        <div className={'content__header text-white'}>
          <h1 className={'fw-bold p-0 m-0'}>{title}</h1>
          <div
            className={`${isUnableToCloseOrChangeForm ? 'd-none' : ''} d-flex justify-content-center align-items-center cursor-pointer`}
            onClick={onCloseTaskContentHandler}>
            <span className={'material-symbols-outlined fw-bold'}>close</span>
          </div>
        </div>

        <div className={'content__body'}>
          {taskVisibility ? (
            data.bodyType === 'HTML' ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: taskQuestion ?? ''
                }}
                className={'markdown-container overflow-auto'}
              />
            ) : (
              <div
                className={
                  'markdown-container overflow-auto d-flex flex-column align-items-center'
                }>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {taskQuestion ?? ''}
                </ReactMarkdown>
              </div>
            )
          ) : (
            <>
              <h2 className={'fw-bold'}>{question}</h2>

              {data.forms &&
                formIndex > -1 &&
                GetFormMedia(gameDocument?.gameDocument!, data.forms[formIndex])
                  .length > 0 && (
                  <div className={'d-flex justify-content-center mb-1'}>
                    <Gallery
                      className={'task-content-media'}
                      items={GetFormMedia(
                        gameDocument?.gameDocument!,
                        data.forms[formIndex]
                      )}
                    />
                  </div>
                )}
            </>
          )}

          <div
            id={'task-content-answer'}
            className={`d-flex flex-column flex-grow-1 ${
              isDisabledAnswer() ? 'disabled-answer' : ''
            }`}>
            <Answer
              key={formIndex}
              answerInput={
                answerType === 'number'
                  ? (contentAnswer[0]?.value ?? 0)
                  : (contentAnswer[0]?.value ?? '')
              }
              answerOptionIndex={
                contentAnswer?.findIndex(
                  (item: ContentAnswer) => item.value as boolean
                ) ?? -1
              }
              answerType={answerType}
              checkboxOption={checkboxOption}
              formId={
                data.forms && formIndex > -1 ? data.forms[formIndex]?.id : ''
              }
              isCorrectAnswer={getPlayerAnswerStatus()}
              isManualScoring={
                data!.forms && formIndex > -1
                  ? data!.forms![formIndex].isManualScoring
                  : false
              }
              name={data?.name}
              onChangeAnswerInput={onSingleInputChangeAnswer}
              onChangeOptionIndex={onRadioChangeAnswer}
              onCheckboxChange={onCheckboxChangeHandler}
              options={options}
              taskId={props.id}
              isDisabled={isDisabledAnswer()}
              isFileUploaded={isFileUploaded}
            />
          </div>
        </div>

        <div className={'content__footer'}>
          <div className={'content border-top'}>
            <div
              className={'align-items-center d-flex justify-content-between'}>
              <div className={`flex-grow-1`}>
                {formIndex > 0 && (
                  <Button
                    onClick={prevQuestion}
                    fillMode={'flat'}
                    className={`${isUnableToCloseOrChangeForm ? 'd-none' : ''}`}>
                    <span className={'d-flex align-items-center'}>
                      <span className={'material-symbols-outlined'}>
                        navigate_before
                      </span>
                      <span>
                        {generateTitleById(
                          '714c5007-ce05-477e-8dc2-8f9502eb4c45',
                          gameDocument,
                          displayLanguageContext.displayLanguageSelected
                            .resources!,
                          'game'
                        ) || 'Previous'}
                      </span>
                    </span>
                  </Button>
                )}
              </div>

              <div className="d-flex justify-items-between">
                {renderPlayerAttempts()}
                {data &&
                  formIndex >= 0 &&
                  data.forms![formIndex].isScoringReduction &&
                  questionScoringReduction && (
                    <ProgressTimeRemaining
                      titleProps={
                        generateTitleById(
                          'de8a3cf6-ee45-4549-931b-d3be8c91ba4c',
                          gameDocument,
                          displayLanguageContext.displayLanguageSelected
                            .resources!,
                          'game'
                        ) || 'Time Remaining'
                      }
                      scoreReduction={questionScoringReduction}
                    />
                  )}
              </div>

              <div className="d-flex align-items-center justify-content-end">
                {!taskVisibility && (
                  <Button
                    fillMode={'flat'}
                    onClick={
                      formIndex + 1 === data?.forms?.length
                        ? onCloseTaskContentHandler
                        : nextQuestion
                    }
                    className={`mr-2 ${isUnableToCloseOrChangeForm ? 'd-none' : ''}`}>
                    <span className={'d-flex align-items-center'}>
                      <span>
                        {formIndex + 1 === data?.forms?.length
                          ? generateTitleById(
                              '48bc820b-40e8-4624-8d46-b68e16013be6',
                              gameDocument,
                              displayLanguageContext.displayLanguageSelected
                                .resources!,
                              'game'
                            ) || 'Close'
                          : generateTitleById(
                              '079c039a-8207-4404-8eb6-558cf5d8477b',
                              gameDocument,
                              displayLanguageContext.displayLanguageSelected
                                .resources!,
                              'game'
                            ) || 'Next'}
                      </span>
                    </span>
                  </Button>
                )}

                {!props.taskDistance ? (
                  <Button
                    id={'btn-task-content-submit'}
                    themeColor={'success'}
                    onClick={
                      taskVisibility
                        ? nextQuestion
                        : (answerType === 'image' || answerType === 'video') &&
                            contentAnswer.length === 0
                          ? onSubmitMediaHandler
                          : onSubmitHandler
                    }
                    disabled={isDisabledAnswer()}>
                    <div className={'d-flex'}>
                      {!taskVisibility ? (
                        <span>{`${
                          formIndex > -1 &&
                          getPlayerAttemptsLeft() > -1 &&
                          getPlayerAttemptsLeft() <
                            data.forms![formIndex].maxAttempts!
                            ? generateTitleById(
                                'c0acec61-3782-4e51-bac5-3cfa930c2586',
                                gameDocument,
                                displayLanguageContext.displayLanguageSelected
                                  .resources!,
                                'game'
                              ) || 'Re-answer'
                            : isLoading
                              ? generateTitleById(
                                  '71a2dffb-7ae7-422f-8423-93b0d4f15a4a',
                                  gameDocument,
                                  displayLanguageContext.displayLanguageSelected
                                    .resources!,
                                  'game'
                                ) || 'Uploading...'
                              : generateTitleById(
                                  '8065dbef-51c3-4e7b-82e8-48ea0bfdf093',
                                  gameDocument,
                                  displayLanguageContext.displayLanguageSelected
                                    .resources!,
                                  'game'
                                ) || 'Answer'
                        }`}</span>
                      ) : (
                        <span>
                          {generateTitleById(
                            'be998642-6834-40d7-b457-55acafbe0fc0',
                            gameDocument,
                            displayLanguageContext.displayLanguageSelected
                              .resources!,
                            'game'
                          ) || 'Continue'}
                        </span>
                      )}
                    </div>
                  </Button>
                ) : (
                  <div className={'d-flex align-items-center'}>
                    <p className={'mr-4 mb-0'}>
                      <b className={'mr-2'}>Distance:</b>
                      {new Intl.NumberFormat('en-EN').format(
                        props.taskDistance
                      )}
                      {' Meters'}
                    </p>
                    <Button
                      id={'btn-task-cancel'}
                      themeColor={'success'}
                      className={'k-button--gradient shadow'}
                      onClick={onCloseTaskContentHandler}>
                      <div className={'d-flex'}>
                        <span>
                          {generateTitleById(
                            '5163981d-0d89-4afb-b0d8-f2d0e42de142',
                            gameDocument,
                            displayLanguageContext.displayLanguageSelected
                              .resources!,
                            'game'
                          ) || 'Cancel'}
                        </span>
                      </div>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  ) : null;
};
